<template>
  <div class="home">
    <ProjectCards />
  </div>
</template>

<script>
// @ is an alias to /src
import ProjectCards from '@/components/ProjectCards.vue';

export default {
  name: 'Home',
  components: {
    ProjectCards
  }
};
</script>
