<template>
  <v-app id="app">
    <v-app-bar app dense fixed>
      <v-toolbar-title>
        <router-link to="/" tag="span" style="cursor: pointer">
          Logan Toler
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn
          v-for="item in menu"
          :key="item.page"
          :to="item.path"
          text
          tile
          left
          >{{ item.page }}</v-btn
        >
      </v-toolbar-items>
    </v-app-bar>
    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    menu: [
      {
        page: 'Home',
        path: '/'
      },
      {
        page: 'About',
        path: '/about'
      }
    ],
    model: 1
  })
};
</script>
