<template>
  <v-row>
    <v-col
      v-for="repo in repos"
      :key="repo.name"
      xs="6"
      sm="6"
      md="4"
      cols="12"
    >
      <v-card
        :href="repo.url"
        target="_blank"
        rel="noopener"
        elevation="1"
        height="100%"
        class="d-flex flex-column"
      >
        <v-card-title>
          <icon-base class="pr-1" width="20" height="20" icon-name="repo"
            ><icon-repo
          /></icon-base>
          <span class="title">{{ repo.name }}</span>
        </v-card-title>
        <v-card-text>{{ repo.description }}</v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn
            v-for="language in repo.language"
            :key="language.name"
            elevation="0"
            small
            rounded
            :color="language.color"
            class="white--text"
            >{{ language.name }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import projects from '@/content/projects.json';
import IconBase from '@/components/IconBase.vue';
import IconRepo from '@/components/icons/IconRepo.vue';

export default {
  components: {
    IconBase,
    IconRepo
  },
  data: function() {
    return {
      repos: projects.pinnedItems // passing array data into Vue
    };
  }
};
</script>

<style scoped>
.v-btn:before {
  background-color: transparent;
}
.v-card--link:before {
  background: none;
}
</style>
